import React from "react";
import "./explore.scss";
import { useTranslation } from "react-i18next";
function Explore() {
  const {t} = useTranslation()
  return (
    <section className="explore-sec">
      <div className="explore-inner">
        <div className="left">
          <img
            src="/assets/images/explore1.webp"
            alt="..."
            className="explorefirst"
          />
          <img
            src="/assets/images/explore2.webp"
            alt="..."
            className="exploresecond"
          />
        </div>
        <div className="right">
          <h1>{t('explore.heading')}</h1>
          <button className="main-btn">{t('explore.button')}</button>
        </div>
      </div>
    </section>
  );
}

export default Explore;
