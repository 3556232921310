import React from "react";
import "./servicespage.scss";
import Banner from "../shared/banner";
import Animatedheading from "../animatedheading/animatedheading";
import Animatedheading2 from "../animatedheading2/animatedheading2";
import Marque from "../marque/marque";
import Servicecard from "../servicecard/servicecard";
import Servicepagecards from "../servicepagecards/servicepagecards";
import Astro from "../astro/astro";
import LetsTalk from "../letsTalk";
import ScrollToTop from "../scroll/scroll";
import { useTranslation } from "react-i18next";
function Servicespage() {
  ScrollToTop();
  const {t} = useTranslation()
  return (
    <section className="servicespage-sec">
      <Banner
        subHead2={t('services2.subHead')}
        head={t('services2.head')}
        textAlign="text-center"
        desktopvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        mobvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        poster="/assets/images/aboutposter.webp"
      />
      <div className="top-sec">
        <div className="top-inner">
          <Animatedheading2
            innerfirst={t('innerText.innerfirst')}
            innersecond={t('innerText.innersecond')}
            innerthird={t('innerText.innerthird')}
          />
          <div className="img-sec">
            <img
              src="/assets/images/servicestop.webp"
              alt="..."
              className="main-img"
            />
            <img
              src="/assets/images/servicesleft.webp"
              alt="..."
              className="left-img"
            />
            <div className="rightimg-div">
              <img
                src="/assets/images/servicesright.webp"
                alt="..."
                className="right-img"
              />
            </div>
          </div>
        </div>
      </div>
      <Marque />
      <Servicepagecards />
      <Astro />
      <LetsTalk />
    </section>
  );
}

export default Servicespage;
