import React, { useState } from "react";
import get from "../../assets/get-to-know/get-to-know.webp";
import top from "../../assets/get-to-know/top-line.webp";
import bottom from "../../assets/get-to-know/bottom-line.webp";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="get-to-know">
        <div className="parent">
          <div className="left-content">
            <h5 className="heading-1 fw-bold text-uppercase   text-light-purple">
              <span className="me-3">{t("about.get")}</span>
              {t("about.to_know")}
              <img src={top} alt="" className="top" />
              <img src={bottom} alt="" className="bottom" />
            </h5>
            <h6 className="fw-bold  mt-5 no-letter-spacing">
              {t("about.digital_maestro")} <br />
              {t("about.for_advanced_tech_solutions")}
            </h6>
            <p className=" mt-2">{t("about.welcome_message")}</p>
          </div>

          <div className="right-content">
            <div className="layers">
              <div className="mainimg-div">
                <img src={get} alt="img" className="img-fluid main-img" />
                <div className="btn-wrapper" onClick={showModal}>
                  <img
                    src="/assets/images/videobtn.png"
                    alt=""
                    className="videomain-btn bg-white  rounded-circle"
                  />
                  <img
                    src="/assets/images/videoanimate.webp"
                    alt=""
                    className="animate-btn"
                  />
                </div>
              </div>

              <div className="inner"></div>
              <div className="outer"></div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="video-modal"
        width="1000px"
      >
        <>
          <video
            autoPlay
            loop
            playsInline
            className="w-100 h-100 object-cover rounded"
          >
            <source
              src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/serviceVideo.mp4"
              type="video/mp4"
            />
          </video>
        </>
      </Modal>
    </>
  );
};

export default About;
