import React from "react";
import "./heading.scss";
function Heading(props) {
  return (
    <section className="heading-sec">
      <h1>{props.outer}</h1>
      <div className="main-head">
        <div>
          <h2>{props.inner}</h2>
        </div>
      </div>
    </section>
  );
}

export default Heading;
