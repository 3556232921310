import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import EffectCarousel from "./effect-carousel.esm";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "./swiper.css";
import team1 from "../../assets/team/1.webp";
import team2 from "../../assets/team/2.webp";
import team3 from "../../assets/team/3.webp";
import team4 from "../../assets/team/4.webp";
import team5 from "../../assets/team/5.webp";
import team6 from "../../assets/team/6.webp";
import team7 from "../../assets/team/7.webp";
import team8 from "../../assets/team/8.webp";
import team9 from "../../assets/team/9.webp";
import team10 from "../../assets/team/10.webp";
import team11 from "../../assets/team/11.webp";
import team12 from "../../assets/team/12.webp";
import team13 from "../../assets/team/13.webp";
import team14 from "../../assets/team/14.webp";
import team15 from "../../assets/team/15.webp";
import team16 from "../../assets/team/16.webp";

import day1 from "../../assets/day-at-octa/Day 2 (1).webp";
import day2 from "../../assets/day-at-octa/Day 2 (2).webp";
import day3 from "../../assets/day-at-octa/Day 2 (3).webp";
import day4 from "../../assets/day-at-octa/Day 2 (4).webp";
import day5 from "../../assets/day-at-octa/Day 2 (5).webp";
import day6 from "../../assets/day-at-octa/Day 2 (6).webp";
import day7 from "../../assets/day-at-octa/Day 2 (7).webp";
import day8 from "../../assets/day-at-octa/Day 2 (8).webp";

import day9 from "../../assets/day-at-octa/Day 2 (10).webp";
import day10 from "../../assets/day-at-octa/Day 2 (11).webp";
import day11 from "../../assets/day-at-octa/T1 (1).webp";
import day12 from "../../assets/day-at-octa/T1 (8).webp";
import day13 from "../../assets/day-at-octa/T1 (9).webp";
import day14 from "../../assets/day-at-octa/T1 (11).webp";
import day15 from "../../assets/day-at-octa/T1 (12).webp";
import day16 from "../../assets/day-at-octa/T1 (13).webp";

import { useTranslation } from "react-i18next";
const DayAtOctaloop = () => {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <div className="day-at-octaloop">
        <h3 className="fw-bold text-center mb-5">
          {t("dayAtOctaloop.heading")}
        </h3>
        <p className="fw-medium text-center mb-5">
          {t("dayAtOctaloop.description")}
        </p>

        <Carousel activeIndex={index} onSelect={handleSelect} controls>
          <Carousel.Item>
            <div className="team-wrapper">
              <div className="team-img-wrapper">
                <img src={day1} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day2} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day3} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day4} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day5} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day6} alt="" />
              </div>

              <div className="team-img-wrapper">
                <img src={day7} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day8} alt="" />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="team-wrapper">
              <div className="team-img-wrapper">
                <img src={day9} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day10} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day11} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day12} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day13} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day14} alt="" />
              </div>

              <div className="team-img-wrapper">
                <img src={day15} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={day16} alt="" />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="team-wrapper">
              <div className="team-img-wrapper">
                <img src={team1} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team2} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team3} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team4} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team5} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team6} alt="" />
              </div>

              <div className="team-img-wrapper">
                <img src={team7} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team8} alt="" />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="team-wrapper">
              <div className="team-img-wrapper">
                <img src={team9} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team10} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team11} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team12} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team13} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team14} alt="" />
              </div>

              <div className="team-img-wrapper">
                <img src={team15} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team16} alt="" />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default DayAtOctaloop;
