import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Hire = () => {
  const {t} = useTranslation()
  return (
    <div>
      <div className="hiring d-flex flex-column">
        <h3 className="text-center fw-bold">{t('hire.heading')}</h3>
        <p className="text-center fw-medium pt-4 pb-5">
          {t('hire.description')}
        </p>
        <div>
          <button
            className="main-btn m-auto d-block"
            onClick={() => toast(t('hire.toastMessage'))}
          >
            {t('hire.button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hire;
