import React from "react";
import "./quote";
import "./quote.scss";
import { useTranslation } from "react-i18next";
function Quote() {
  const {t} = useTranslation()
  return (
    <section className="quote-sec">
      <div className="top">
        <div className="inner">
          <h3>{t('author_info.name')}</h3>
          <h4>{t('author_info.title')} </h4>
        </div>
      </div>
      <p className="large-para">
      {t('author_info.quote')}
      </p>
    </section>
  );
}

export default Quote;
