import React, { useRef } from "react";
import { Icon } from "@iconify/react";
// import CustomCheckBox from "../shared/customCheckBox";
import CustomCheckBox from "./shared/customCheckBox";
import { useState } from "react";
import Input from "./shared/input";
import { useEffect } from "react";
import emailjs from "@emailjs/browser";
import { storage } from "../config";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const Contactinfo = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    companyWebsite: "",
    message: "",
    services: [],
    file: null,
  });

  const [formErrors, setFormErrors] = useState({});
  const [issubmit, setIssubmit] = useState(false);

  const form = useRef();
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  // const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  // Initialize Firebase Storage.
  const storage = getStorage();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedFile(file);
      setImageUrl(imageUrl);
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (values.email == "") {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email Format";
    }
    if (values.firstName == "") {
      errors.firstName = "First name is required";
    }
    if (values.phone == "") {
      errors.phone = "Phone Number is required";
    }

    if (values.message == "") {
      errors.message = "Message is required";
    }

    if (!values.services.length)
      errors.services = "Select at least one service";

    return errors;
  };
  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      const newServices = checked
        ? [...formData.services, value]
        : formData.services.filter((service) => service !== value);
      setFormData((prevState) => ({
        ...prevState,
        services: newServices,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    if (selectedFile && selectedFile.size > 1024 * 1024) {
      toast.error("File size should not be greater than 1 MB");
      setLoading(false);
      return;
    }

    const errors = validate(formData);
    setFormErrors(errors);
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      return;
    }

    if (selectedFile) {
      const storageRef = ref(storage, `images/${selectedFile.name}`);
      const uploadTaskSnapshot = await uploadBytes(storageRef, selectedFile);
      formData.file = await getDownloadURL(uploadTaskSnapshot.ref);
    }

    emailjs
      .send("service_31l9mum", "template_dlw3fgk", formData, {
        publicKey: "b7Yxfqta2A6xl9pZG",
      })
      .then(
        (result) => {
          toast.success("Form submitted successfully");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            companyName: "",
            companyWebsite: "",
            message: "",
            services: [],
            file: null,
          });
          setSelectedFile();
          setLoading(false);
        },
        (error) => {
          toast.error("Failed to submit form");
          setLoading(false);
        }
      );
  };

  return (
    <div>
      <div className="Contactinfo d-flex v-center h-center flex-column">
        {/* <h2>BOOK CONSULTATION</h2> */}
        <div className="content d-flex ">
          <div className="left d-flex flex-column v-center h-center">
            <h1>
              <span className="text-white">{t("contactForm.heading")}</span>
            </h1>
            <label className="small text-white">
              {t("contactForm.chatPrompt")}
            </label>
            <div className="green-shadow"></div>
          </div>
          <div className="right d-flex flex-column">
            <div className="input-box-wrapper ">
              <div className="d-flex flex-column gap-1">
                <Input
                  label={t("contactForm.firstName")}
                  labelClass="mb-2"
                  inputType="text"
                  wrapperClasses="w-100"
                  onChange={handleInputChange}
                  name="firstName"
                  value={formData.firstName}
                />
                <span style={{ fontSize: "12px", color: "red" }}>
                  {formErrors.firstName}
                </span>
              </div>
              <Input
                label={t("contactForm.lastName")}
                labelClass="mb-2"
                inputType="text"
                wrapperClasses="w-100"
                onChange={handleInputChange}
                name="lastName"
                value={formData.lastName}
              />

              <div className="d-flex flex-column gap-1">
                <Input
                  label={t("contactForm.email")}
                  labelClass="mb-2"
                  inputType="mail"
                  wrapperClasses="w-100"
                  onChange={handleInputChange}
                  name="email"
                  value={formData.email}
                />
                <span style={{ fontSize: "12px", color: "red" }}>
                  {formErrors.email}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <Input
                  label={t("contactForm.phone")}
                  labelClass="mb-2"
                  inputType="number"
                  wrapperClasses="w-100"
                  onChange={handleInputChange}
                  name="phone"
                  value={formData.phone}
                />
                <span style={{ fontSize: "12px", color: "red" }}>
                  {formErrors.phone}
                </span>
              </div>

              <Input
                label={t("contactForm.companyName")}
                labelClass="mb-2"
                inputType="text"
                wrapperClasses="w-100"
                onChange={handleInputChange}
                name="companyName"
                value={formData.companyName}
              />

              <Input
                label={t("contactForm.companyWebsite")}
                labelClass="mb-2"
                inputType="url"
                wrapperClasses="w-100"
                onChange={handleInputChange}
                name="companyWebsite"
                value={formData.companyWebsite}
              />
            </div>

            <div className="service d-flex flex-column">
              <p className="medium">{t("contactForm.servicesPrompt")}</p>
              <div className="d-flex flex-column gap-3">
                <div className="labels">
                  <CustomCheckBox
                    label={t("service.ai")}
                    id="ai"
                    name="services"
                    value="Artificial Intelligence"
                    checked={formData.services.includes(
                      "Artificial Intelligence"
                    )}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.web3")}
                    id="web3"
                    name="services"
                    value="Web 3.0"
                    checked={formData.services.includes("Web 3.0")}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.mobileApp")}
                    id="mobileApp"
                    name="services"
                    value="Mobile Application"
                    checked={formData.services.includes("Mobile Application")}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.uix")}
                    id="uix"
                    name="services"
                    value="UI / UX Design"
                    checked={formData.services.includes("UI / UX Design")}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.2d3d")}
                    id="2d3d"
                    name="services"
                    value="2D & 3D Designing"
                    checked={formData.services.includes("2D & 3D Designing")}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.gamedev")}
                    id="gamedev"
                    name="services"
                    value="Game Development"
                    checked={formData.services.includes("Game Development")}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.salesmarket")}
                    id="salesmarket"
                    name="services"
                    value="Sales & Marketing"
                    checked={formData.services.includes("Sales & Marketing")}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.qa")}
                    id="qa"
                    name="services"
                    value="Quality Assurance"
                    checked={formData.services.includes("Quality Assurance")}
                    onChange={handleInputChange}
                  />
                  <CustomCheckBox
                    label={t("service.others")}
                    id="others"
                    name="services"
                    value="Others"
                    checked={formData.services.includes("Others")}
                    onChange={handleInputChange}
                  />
                </div>
                <span style={{ fontSize: "12px", color: "red" }}>
                  {formErrors.services}
                </span>
              </div>
            </div>

            <div className="message">
              <p className="medium">{t("contactForm.message")}</p>
              <div className="d-flex flex-column gap-1">
                <textarea
                  id=""
                  placeholder="Write your message.."
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
                <span style={{ fontSize: "12px", color: "red" }}>
                  {formErrors.message}
                </span>
              </div>
            </div>

            <div className="two-buttons d-flex justify-content-between">
              <label
                id="1"
                className="d-flex v-center h-center"
                // ref={fileInputRef}
              >
                {t("contactForm.uploadFile")}
                <input
                  type="file"
                  name="file"
                  id="1"
                  className="d-none"
                  onChange={handleFileChange}
                  // onChange={(e) => handleInputChange(e.target.files[0])}
                />
                <Icon
                  icon="material-symbols:upload"
                  width="24"
                  height="24"
                  className="ms-2"
                />
              </label>

              <button
                className="rightbutton"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading ? (
                  <Icon
                    icon="line-md:loading-loop"
                    style={{ color: "white" }}
                  />
                ) : (
                  t("contactForm.sendMessage")
                )}
              </button>
            </div>
            {selectedFile && (
              <p style={{ fontSize: "13px" }}>{selectedFile.name}</p>
            )}
          </div>
        </div>
        <div className="locations">
          <div className="address">
            <div className="left">
              <div className="flag-img ">
                <img src="/assets/icons/dubai.png" alt="..." />
              </div>
            </div>
            <div className="right">
              <div className="location">
                <img src="/assets/icons/location.svg" alt=".." />
                <p className="large-para">{t("contactForm.title1")}</p>
                <span className="large-paraDeci">
                  {t("contactForm.address1")}
                </span>
              </div>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.871275740967!2d55.23982458683503!3d25.173823975229272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69e970406c7b%3A0x917a18425e33fb33!2sAL%20GOZE%20BUILDING%20-%20Al%20Quoz%20-%20Al%20Quoz%201%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1716888244296!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="address">
            <div className="left">
              <div className="flag-img ">
                <img src="/assets/icons/pakistan.png" alt="..." />
              </div>
            </div>
            <div className="right">
              <div className="location">
                <img src="/assets/icons/location.svg" alt=".." />
                <p className="large-para">{t("contactForm.title2")}</p>
                <span className="large-paraDeci">
                  {t("contactForm.address2")}
                </span>
              </div>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.477244126342!2d73.01649348849345!3d33.64479397033705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaad3ce4c22b9eccd%3A0x305a3d6f45049dc7!2sOctaloop%20Technologies!5e0!3m2!1sen!2s!4v1709112956713!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="address">
            <div className="left">
              <div className="flag-img ">
                <img src="/assets/icons/pakistan.png" alt="..." />
              </div>
            </div>
            <div className="right">
              <div className="location">
                <img src="/assets/icons/location.svg" alt=".." />
                <p className="large-para">{t("contactForm.title3")}</p>
                <span className="large-paraDeci">
                  {t("contactForm.address3")}
                </span>
              </div>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3402.831356799018!2d74.26986307560968!3d31.473824974235374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDI4JzI1LjgiTiA3NMKwMTYnMjAuOCJF!5e0!3m2!1sen!2s!4v1715757073765!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>

          <div className="address">
            <div className="left">
              <div className="flag-img ">
                <img src="/assets/icons/France.svg" alt="..." />
              </div>
            </div>
            <div className="right">
              <div className="location">
                <img src="/assets/icons/location.svg" alt=".." />
                <p className="large-para">{t("contactForm.title4")}</p>
                <span className="large-paraDeci">
                  {t("contactForm.address4")}
                </span>
              </div>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.3356504658373!2d2.302796276867343!3d48.87087759982703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fc3f24fffff%3A0xf12770d93f1767d4!2s66%20Av.%20des%20Champs-%C3%89lys%C3%A9es%2C%2075008%20Paris%2C%20France!5e0!3m2!1sen!2s!4v1722426465691!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>

          <div className="address">
            <div className="left">
              <div className="flag-img ">
                <img src="/assets/icons/Germany.svg" alt="..." />
              </div>
            </div>
            <div className="right">
              <div className="location">
                <img src="/assets/icons/location.svg" alt=".." />
                <p className="large-para">{t("contactForm.title5")}</p>
                <span className="large-paraDeci">
                  {t("contactForm.address5")}
                </span>
              </div>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37146.38181785965!2d8.028270198901032!3d49.2202340777296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47964f779470f6c5%3A0x4a212f15e1e7ac2a!2sNonnenstra%C3%9Fe%2026%2C%2067480%20Edenkoben%2C%20Germany!5e0!3m2!1sen!2s!4v1722503731458!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>

          {/* Uk */}
          <div className="address">
            <div className="left">
              <div className="flag-img ">
                <img src="\assets\icons\united-kingdom.png" alt="..." />
              </div>
            </div>
            <div className="right">
              <div className="location">
                <img src="/assets/icons/location.svg" alt=".." />
                <p className="large-para">{t("contactForm.title6")}</p>
                <span className="large-paraDeci">
                  {t("contactForm.address6")}
                </span>
              </div>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.454808433258!2d-0.3938581228268814!3d51.55989540686627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766cd9b9137101%3A0x803456ba5a23c0fd!2sLong%20Dr%2C%20Ruislip%2C%20UK!5e0!3m2!1sen!2s!4v1724225174625!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactinfo;
