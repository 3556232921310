import React from "react";
import facebook from "../assets/icons/facebook.svg";
import linkedIn from "../assets/icons/linkedin.svg";
import insta from "../assets/icons/insta.svg";
import twitter from "../assets/icons/twitter.svg";
import { useTranslation } from "react-i18next";
const ContactBanner = () => {
  const {t} = useTranslation()
  return (
    <div>
      <div className="contact-banner">
        <h1 className="text-uppercase text-center  ">{t('contactBanner.heading')}</h1>

        <div className="social-warapper">
          <div className="position-relative">
            <a href="https://www.facebook.com/octalooptech" target="_blank">
              <img src={facebook} alt="facebook" />
            </a>

            <div className="divider">
              <span className="circle-1"></span>
              <span className="circle-2"></span>
              <span className="circle-3"></span>
              <span className="circle-4"></span>

              <span className="circle-center"></span>

              <span className="circle-4"></span>
              <span className="circle-3"></span>
              <span className="circle-2"></span>
              <span className="circle-1"></span>
            </div>

            <p
              onClick={() =>
                window.open("https://www.facebook.com/octalooptech", "_blank")
              }
            >
              octaloop.fb.com
            </p>
          </div>

          <div>
            <a href="https://www.instagram.com/octaloop.io/" target="_blank">
              <img src={insta} alt="insta" />

              <div className="divider ">
                <span className="circle-1"></span>
                <span className="circle-2"></span>
                <span className="circle-3"></span>
                <span className="circle-4"></span>

                <span className="circle-center"></span>

                <span className="circle-4"></span>
                <span className="circle-3"></span>
                <span className="circle-2"></span>
                <span className="circle-1"></span>
              </div>
            </a>

            <p
              onClick={() =>
                window.open("https://www.instagram.com/octaloop.io/", "_blank")
              }
            >
              octaloop.instagram.com
            </p>
          </div>

          <div>
            <a
              href="https://www.linkedin.com/company/octaloop-technologies"
              target="_blank"
            >
              <img src={linkedIn} alt="linkedin" />

              <div className="divider">
                <span className="circle-1"></span>
                <span className="circle-2"></span>
                <span className="circle-3"></span>
                <span className="circle-4"></span>

                <span className="circle-center"></span>

                <span className="circle-4"></span>
                <span className="circle-3"></span>
                <span className="circle-2"></span>
                <span className="circle-1"></span>
              </div>
            </a>

            <p
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/octaloop-technologies",
                  "_blank"
                )
              }
            >
              octaloop.linkedIn.com
            </p>
          </div>

          <div>
            <a href="https://twitter.com/OctaloopTech" target="_blank">
              <img src={twitter} alt="twitter" />
            </a>

            <p
              onClick={() =>
                window.open("https://twitter.com/OctaloopTech", "_blank")
              }
            >
              octaloop.twitter.com
            </p>
          </div>
        </div>
        <video playsInline autoPlay muted loop>
          <source
            src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/workflow.mp4"
            type="video/mp4"
          />
        </video>
        <div className="bg-layer"></div>
      </div>
    </div>
  );
};

export default ContactBanner;
