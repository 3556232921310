import React from "react";
import ml from "../../../assets/deciphering/M-L.png";
import NLP from "../../../assets/deciphering/NLP.png";
import NNE from "../../../assets/deciphering/NNE.png";
import EI from "../../../assets/deciphering/E-I.png";
import Heading from "../../heading/heading";
import Animatedheading from "../../animatedheading/animatedheading";
import { useTranslation } from "react-i18next";
const DecipheringCards = () => {
  const {t} = useTranslation()
  const cardInfo = [
    {
      profile: ml,
      heading: t('decipher.machine_learning_mastery'),
      para: t('decipher.ml_description'),
    },

    {
      profile: NLP,
      heading: t('decipher.natural_language_processing'),
      para: t('decipher.nlp_description')
    },

    {
      profile: NNE,
      heading: t('decipher.neural_networks_expertise'),
      para: t('decipher.nne_description')
    },

    {
      profile: EI,
      heading: t('decipher.ethical_intelligence'),
      para: t('decipher.ei_description')
    },
  ];

  return (
    <div className="deciphering-sec">
      <div className="heading">
        <Animatedheading
          outer={t('decipher.technologies')}
          innerfirst={t('decipher.deciphering')}
          innersecond={t('decipher.the_ai_virtuoso')}
        />
        <p className="detail-para">
        {t('decipher.intro_text')}
        </p>
      </div>

      <div className="Deciphering-cards">
        <img
          src="/assets/deciphering-background.png"
          alt="..."
          className="backimg"
        />
        {cardInfo.map((items, index) => (
          <div className="card-content" key={index}>
            <img src={items.profile} alt="img" />
            <label className="xlarge-para"> {items.heading} </label>
            <p className="large-paraDeci">{items.para}</p>
            <div className="circle"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DecipheringCards;
