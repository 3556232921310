import React from "react";
import "./careeroppertunity.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Careeroppertunity() {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <section className="careeroppertunity-sec">
      <div className="inner">
        <div className="left">
          <h1>
            {t('carrer.joinUsFor')}<span>{t('carrer.better')}</span> {t('carrer.future')}
          </h1>
          <button className="main-btn" onClick={() => navigate("/contact-us")}>
            {t('carrer.careerOpportunity')}
          </button>
        </div>
        <div className="right">
          <img
            src="/assets/images/homecareer.webp"
            alt=""
            className="center-img"
          />
          <img
            src="/assets/images/animatedimg.webp"
            alt=""
            className="animated-img"
          />
        </div>
      </div>
    </section>
  );
}

export default Careeroppertunity;
