import React from "react";
import { useTranslation } from "react-i18next";

const TestimonialCard = () => {
  const {t} = useTranslation()
  return (
    <>
      <div className="for-layer">
        <div className="slide-1">
          <div className="top">
            <img
              src="/assets/images/sarah.webp"
              alt="img"
              className="img-60 bg-black rounded-circle"
            />
            <div className="d-flex">
              <img
                src="/assets/images/comma.svg"
                alt="img"
                className="comma hover-hide"
              />
              <img
                src="/assets/images/comma2.svg"
                alt="img"
                className="comma hover-show d-none"
              />
            </div>
          </div>
          <p className="large-para">
           {t('testimonials.para5')}
          </p>
          <div className="bottom">
            <h4>
              {t('testimonials.name5')}
            </h4>
            <p className="small-para">
              {t('testimonials.position5')}
            </p>
          </div>
        </div>
        <div className="layer"></div>
      </div>
    </>
  );
};

export default TestimonialCard;
