import React from "react";
import "./servicecard.scss";
import { Icon } from "@iconify/react";
function Servicecard(props) {
  return (
    <section className="servicecard-sec">
      <div className="servicecard-top">
        <img src={props.imgsrc} alt="..." className="cardtop-img" />
        <div className="top-layer">
          <h3>{props.heading}</h3>
          <p className="large-para">{props.para}</p>
        </div>
      </div>
      <div className="servicecard-bottom">
        <div className="bottom-left">
          <h1>{props.number}</h1>
          <h6>{props.heading}</h6>
        </div>
        <div className="servicecard-circle">
          <Icon icon="solar:arrow-up-broken" />
        </div>
      </div>
    </section>
  );
}

export default Servicecard;
