import React from "react";
import "./astro.scss";
import { useTranslation } from "react-i18next";
function Astro() {
  const {t} = useTranslation()
  return (
    <section className="astro-sec">
      <div className="inner">
        <div className="left">
          <h1>
            {t('astro.headingPart1')} <br className="mobile-hide" /> {t('astro.headingPart2')}
          </h1>
          <p className="large-para">
          {t('astro.largePara')}
          </p>
        </div>
        <div className="right">
          <img
            src="/assets/images/astroback.png"
            alt="..."
            className="astro-back"
          />
          <img
            src="/assets/images/astromain.png"
            alt="..."
            className="astro-main"
          />
        </div>
      </div>
    </section>
  );
}

export default Astro;
