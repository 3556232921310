import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Innovate = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <>
      <section className="innovation ">
        <div className="left-content">
          <span>{t('innovate.span')}</span>
          <h3>{t('innovate.title')}</h3>
          <button className="main-btn" onClick={() => navigate("/contact-us")}>
          {t('innovate.button')}
          </button>
        </div>
        <div className="right-content">
          <p className="fw-medium lh-sm">
          {t('innovate.para1')}
          </p>
          <div className="output-wrapper">
            <div>
              <h3 className="text-roboto fw-bold">{t('innovate.percentage1')}</h3>
              <p className="medium">{t('innovate.description1')}</p>
            </div>
            <div>
              <h3 className="text-roboto fw-bold">{t('innovate.percentage2')}</h3>
              <p className="medium">{t('innovate.description2')}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Innovate;
