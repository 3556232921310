import React from "react";
import Banner from "../components/shared/banner";
import Stats from "../components/stats";
import Vision from "../components/vision";
import Teammembercard from "../components/teammembercard/teammembercard";
import Homebod from "../components/homebod/homebod";
import Innovate from "../components/innovate";
import DayAtOctaloop from "../components/dayAtOctaloop/dayAtOctaloop";
import LetsTalk from "../components/letsTalk";
import Hire from "../components/hire";
import ScrollToTop from "../components/scroll/scroll";
import { useTranslation } from "react-i18next";

const About = () => {
  ScrollToTop();
  const {t} = useTranslation()
  return (
    <div className="overflowX-hidden">
      <Banner
        subHead2={t('banner2.subHead')}
        head={t('banner2.head')}
        textAlign="text-center"
        desktopvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        mobvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        poster="/assets/images/aboutposter.webp"
      />
      <Stats />
      <Vision />
      <Homebod />
      <Innovate />
      <DayAtOctaloop />
      <LetsTalk />
      <Hire />
    </div>
  );
};

export default About;
