import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/language";

const HappyClient = () => {
  const { t } = useTranslation();

  const { language, updateLanguage } = useContext(LanguageContext);

  return (
    <div>
      <div className="happy-client">
        <div className="content">
          <div className="left">
            <h2>{t("client.clients_count")}</h2>
            <h3 className={language == "en" ? "" : "text-center"}>
              {t("client.happy_clients")}
            </h3>
          </div>
          <div className="right">
            <h6>{t("client.projects_completed")}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HappyClient;
