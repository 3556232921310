import React from "react";
import "./homebod.scss";
import Animatedheading from "../animatedheading/animatedheading";
import Teammembercard from "../teammembercard/teammembercard";
import { useTranslation } from "react-i18next";
function Homebod() {
  const { t } = useTranslation();
  const data = [
    {
      imgsrc: "/assets/images/bodmembers/aamir.webp",
      linkedin: "https://www.linkedin.com/in/aamir-ahmed-12b6a4139/",
      name: t("teamMembers.name1"),
      role: t("teamMembers.role1"),
    },
    {
      imgsrc: "/assets/images/bodmembers/wasaam.webp",
      linkedin: "https://www.linkedin.com/in/wasaam/",
      name: t("teamMembers.name2"),
      role: t("teamMembers.role2"),
    },
    {
      imgsrc: "/assets/images/bodmembers/murray2.webp",
      linkedin: "https://www.linkedin.com/in/murray-marderosian-83136220a",
      name: t("teamMembers.name3"),
      role: t("teamMembers.role3"),
    },
    // {
    //   imgsrc: "/assets/images/bodmembers/manzoor.webp",
    //   facebook: "#",
    //   twitter: "#",
    //   linkedin: "#",
    //   insta: "#",
    //   name: "MANZOOR TAREEN",
    //   role: "VICE MD",
    // },
    // {
    //   imgsrc: "/assets/images/bodmembers/noman.webp",
    //   facebook: "#",
    //   twitter: "#",
    //   linkedin: "#",
    //   insta: "#",
    //   name: "NAUMAN MIR",
    //   role: "COO",
    // },
    // {
    //   imgsrc: "/assets/images/bodmembers/uzair.webp",
    //   facebook: "#",
    //   twitter: "#",
    //   linkedin: "#",
    //   insta: "#",
    //   name: "MUHAMMAD UZAIR",
    //   role: "MARKETING executive",
    // },
  ];

  return (
    <section className="homebod-sec">
      <Animatedheading
        outer={t("animatedHeading.outer")}
        innerfirst={t("animatedHeading.innerfirst")}
        innersecond={t("animatedHeading.innersecond")}
      />
      <p className="detail-para">{t("detailPara")}</p>
      <div className="team-cards">
        {data.map((item, index) => (
          <Teammembercard
            imgsrc={item.imgsrc}
            key={index}
            linkedin={item.linkedin}
            name={item.name}
            role={item.role}
          />
        ))}
      </div>
      {/* <button className="main-btn">Meet The Team</button> */}
    </section>
  );
}

export default Homebod;
