import React from "react";
import "./helpinghands.scss";
import { useTranslation } from "react-i18next";
function Helpinghands() {
  const {t} = useTranslation()
  return (
    <section className="helpinghands-sec">
      <img
        src="/assets/images/helpinghandsback.webp"
        alt=""
        className="helping-back img-fluid"
      />
      <div className="helping-div">
        <h2>{t('helping_hands.title')}</h2>
      </div>
    </section>
  );
}

export default Helpinghands;
