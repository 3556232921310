import React from "react";
import vision from "../assets/img/vission.webp";
import { useTranslation } from "react-i18next";
const Vision = () => {
  const {t} = useTranslation()
  return (
    <div>
      <div className="vision">
        <div className="content">
          <div className="left-content">
            <h3 className="text-capitalize fw-bold">
              {t('vision.titleVision')}
              <span>{t('vision.titleMission')}</span>
            </h3>
            <div className="d-flex flex-column gap-3 ">
              <p className="fw-medium lh-sm">
                {t('vision.para1')}
              </p>

              <p className="fw-medium lh-sm">
              {t('vision.para2')}
               
              </p>
              <p className="fw-medium lh-sm">
              {t('vision.para3')}
               
              </p>
            </div>
          </div>
          <div className="right-content">
            <img src={vision} alt="vision" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
