import React from "react";
import Contactinfo from "../components/contactinfo";
import ContactBanner from "../components/contactBanner";
import ScrollToTop from "../components/scroll/scroll";

const Contact = () => {
  ScrollToTop();
  return (
    <div>
      <ContactBanner />
      <Contactinfo />
    </div>
  );
};

export default Contact;
