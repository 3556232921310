import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonials.scss";
import TestimonialCard from "./testimonialCard";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const {t} = useTranslation()
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="ourClient">
        <div className="head">
          <h3>
            {t('test.testim')}<span>{t('test.o')}</span>{t('test.nials')}
          </h3>
        </div>
        <div className="clientReview-slider">
          <Slider {...settings} className="d-nonse">
            {/* <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <img src="/assets/images/sarah.webp" alt="img" />
                  <div className="d-flex">
                    <img
                      src="/assets/images/comma.svg"
                      alt="img"
                      className="comma hover-hide"
                    />
                    <img
                      src="/assets/images/comma2.svg"
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p className="large-para">
                  Octaloop Technologies stands out as a beacon of innovation.
                  Their forward-thinking solutions have truly transformed our
                  digital landscape. The commitment to excellence and
                  cutting-edge technology is evident in every interaction.
                </p>
                <div className="bottom">
                  <h4>Sarah Johnson</h4>
                  <p className="small-para">CEO, TechScape Solutions</p>
                </div>
              </div>
              <div className="layer"></div>
            </div> */}
            <TestimonialCard />
            <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <img src="/assets/images/paul.svg" alt="img" />

                  <div className="d-flex">
                    <img
                      src="/assets/images/comma.svg"
                      alt="img"
                      className="comma hover-hide"
                    />
                    <img
                      src="/assets/images/comma2.svg"
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p className="large-para">
                  {t('testimonials.para1')}
                </p>
                <div className="bottom">
                  <h4>
                  {t('testimonials.name1')}

                  </h4>
                  <p className="small-para">
                  {t('testimonials.position1')}

                  </p>
                </div>
              </div>
              <div className="layer"></div>
            </div>

            <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <img src="/assets/images/peter.svg" alt="img" />

                  <div className="d-flex">
                    <img
                      src="/assets/images/comma.svg"
                      alt="img"
                      className="comma hover-hide"
                    />
                    <img
                      src="/assets/images/comma2.svg"
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p className="large-para">
                {t('testimonials.para2')}
                  
                </p>
                <div className="bottom">
                  <h4>
                  {t('testimonials.name2')}

                  </h4>
                  <p className="small-para">
                    {" "}
                  {t('testimonials.position2')}
                    
                  </p>
                </div>
              </div>
              <div className="layer"></div>
            </div>

            <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <img src="/assets/images/johnny.svg" alt="img" />

                  <div className="d-flex">
                    <img
                      src="/assets/images/comma.svg"
                      alt="img"
                      className="comma hover-hide"
                    />
                    <img
                      src="/assets/images/comma2.svg"
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p className="large-para">
                {t('testimonials.para3')}
                
                </p>
                <div className="bottom">
                  <h4>
                  {t('testimonials.name3')}

                  </h4>
                  <p className="small-para">
                  {t('testimonials.position3')}

                  </p>
                </div>
              </div>
              <div className="layer"></div>
            </div>

            <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <img src="/assets/images/waldo.svg" alt="img" />

                  <div className="d-flex">
                    <img
                      src="/assets/images/comma.svg"
                      alt="img"
                      className="comma hover-hide"
                    />
                    <img
                      src="/assets/images/comma2.svg"
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p className="large-para">
                {t('testimonials.para4')}
                 
                </p>
                <div className="bottom">
                  <h4>
                  {t('testimonials.name4')}

                  </h4>
                  <p className="small-para">
                  {t('testimonials.position4')}
                  
                  </p>
                </div>
              </div>
              <div className="layer"></div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
