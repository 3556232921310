import React from "react";
import { useTranslation } from "react-i18next";

const Stats = () => {
  const {t} = useTranslation()
  return (
    <div>
      <div className="statistics pb-5">
        <div className="stat-card-wrapper d-flex justify-content-between">
          <div className="stats-card d-flex flex-column text-center">
            <h2>{t('stats.projectsCompletedno')}</h2>
            <p className="large ">{t('stats.projectsCompleted')}</p>
          </div>

          <div className="stats-card d-flex flex-column text-center">
            <h2>{t('stats.projectsCompletedno')}</h2>
            <p className="large ">{t('stats.happyCustomer')}</p>
          </div>

          <div className="stats-card d-flex flex-column text-center">
            <h2>{t('stats.yearsOfExperienceno')}</h2>
            <p className="large ">{t('stats.yearsOfExperience')}</p>
          </div>

          <div className="stats-card d-flex flex-column text-center">
            <h2>{t('stats.countriesServedno')}</h2>
            <p className="large ">{t('stats.countriesServed')}</p>
          </div>
        </div>
        <p className="text-center mt-5 pt-5 w-75 m-auto fw-medium details lh-sm">
         {t('stats.details')}
        </p>
      </div>
    </div>
  );
};

export default Stats;
