import React from "react";
import "./animatedheading2.scss";
function Animatedheading2(props) {
  return (
    <section className="animatedheading2-sec">
      <img src="/assets/icons/line.svg" alt="..." className="top-img" />
      <div className="top-div">
        <h3 className="normal-title">{props.innerfirst}</h3>

        <div>
          <h3 className="shadow-title">{props.innersecond}</h3>
        </div>
      </div>
      <h3 className="bottom-div normal-shadow">{props.innerthird}</h3>
      <img src="/assets/icons/line.svg" alt="..." className="bottom-img" />
    </section>
  );
}

export default Animatedheading2;
