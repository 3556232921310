import React, { useContext, useEffect, useState } from "react";
import "./blogs.scss";
import Heading from "../heading/heading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/language";

function Blogs() {
  const { t } = useTranslation();
  const data = [
    {
      imgsrc: "/assets/images/blog1.webp",
      day: t("blogPosts.day1"),
      month: t("blogPosts.month1"),
      heading: t("blogPosts.heading1"),
      data: t("blogPosts.data1"),
      name: t("blogPosts.name1"),
      comments: t("blogPosts.comments1"),
      pagelink:
        "https://medium.com/@octaloop.tech/mastering-the-machine-propel-your-business-forward-with-ai-dominance-de67fd47d264",
    },
    {
      imgsrc: "/assets/images/blog2.webp",
      day: t("blogPosts.day2"),
      month: t("blogPosts.month2"),
      heading: t("blogPosts.heading2"),
      data: t("blogPosts.data2"),
      name: t("blogPosts.name2"),
      comments: t("blogPosts.comments2"),
      pagelink:
        "https://medium.com/@octaloop.tech/everyday-ai-wonders-unveiling-examples-bb3955df03c2",
    },
    {
      imgsrc: "/assets/images/blog3.webp",
      day: t("blogPosts.day3"),
      month: t("blogPosts.month3"),
      heading: t("blogPosts.heading3"),
      data: t("blogPosts.data3"),
      name: t("blogPosts.name3"),
      comments: t("blogPosts.comments3"),
      pagelink:
        " https://medium.com/@octaloop.tech/octaloop-technologies-unlocking-the-power-of-ai-blockchain-metaverse-d63531609eb6",
    },
  ];
  const { language, updateLanguage } = useContext(LanguageContext);

  return (
    <section className="blogs-sec">
      <div className="inner">
        <Heading outer={t("blogse.outer")} inner={t("blogse.inner")} />
        <div className="blog-cards">
          {data.map((item, index) => (
            <a href={item.pagelink} target="_blank" key={index}>
              <div className="blog-card">
                <div className="img-div">
                  <img src={item.imgsrc} alt="..." className="blog-img" />
                  <div className="date">
                    <p className="large-para">{item.day}</p>
                    <p className="large-para">{item.month}</p>
                  </div>
                </div>
                <div className="content">
                  <h6 className={language == "ar" ? "text-end mt-2" : ""}>
                    {item.heading}
                  </h6>
                  <p
                    className={
                      language == "ar" ? "text-end small-para" : "small-para"
                    }
                  >
                    {item.data.substring(0, 120)}...
                  </p>
                </div>
                <div className="details">
                  <div className="left">
                    <label>
                      <span> By : </span> {item.name}
                    </label>
                  </div>
                  <div className="center"></div>
                  <div className="right">
                    <img src="/assets/icons/comments.svg" alt="" />
                    <label>{item.comments} Comments</label>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Blogs;
