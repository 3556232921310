import React, { useState } from "react";
import "./faqs.scss";
import { Accordion } from "react-bootstrap";
import Heading from "../heading/heading";
import { useTranslation } from "react-i18next";
function Faqs() {
  const [activeKey, setActiveKey] = useState("0");
  const handleAccordionSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };
  const {t} = useTranslation()
  return (
    <section className="faqs-sec">
      <div className="inner">
        <Heading outer={t('FAQ.askAny')} inner={t('FAQ.faqs')} />
        <div className="faqs-accordion">
          <Accordion
            defaultActiveKey="0"
            activeKey={activeKey}
            onSelect={handleAccordionSelect}
            flush
          >
            {[0, 1, 2, 3, 4, 5].map((itemKey) => (
              <Accordion.Item
                key={itemKey}
                eventKey={itemKey.toString()}
                style={{
                  boxShadow:
                    activeKey === itemKey.toString()
                      ? "0px 0px 16.033px 0px rgba(111, 0, 255, 0.22)"
                      : "0px 5.01px 16.033px 0px rgba(8, 15, 52, 0.06)",
                }}
              >
                <Accordion.Header>
                  {itemKey === 0
                    ? t('FAQ.question1')
                    : itemKey === 1
                    ? t('FAQ.question2')
                    : itemKey === 2
                    ? t('FAQ.question3')
                    : itemKey === 3
                    ? t('FAQ.question4')
                    : itemKey === 4
                    ? t('FAQ.question5')
                    : t('FAQ.question6')}
                </Accordion.Header>
                <Accordion.Body>
                  {itemKey === 0
                    ? t('FAQ.answer1')
                    : itemKey === 1
                    ? t('FAQ.answer2')
                    : itemKey === 2
                    ? t('FAQ.answer3')
                    : itemKey === 3
                    ? t('FAQ.answer4')
                    : itemKey === 4
                    ? t('FAQ.answer5')
                    : t('FAQ.answer6')}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
