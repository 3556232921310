import React from "react";
import "./animatedheading.scss";
function Animatedheading(props) {
  return (
    <sec className="animatedheading-sec">
      <h1>{props.outer}</h1>
      <img src="/assets/icons/line.svg" alt="..." className="top-img" />
      <div className="main-head">
        <h3 className="normal-title">{props.innerfirst}</h3>

        <div>
          <h3 className="shadow-title">{props.innersecond}</h3>
        </div>
      </div>
      <img src="/assets/icons/line.svg" alt="..." className="bottom-img" />
    </sec>
  );
}

export default Animatedheading;
