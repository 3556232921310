import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/language";

const Banner = (props) => {
  return (
    <div>
      <section className="banner">
        <video playsInline autoPlay muted loop poster={props.poster}>
          <source src={props.desktopvid} type="video/mp4" className="desktop" />
          <source src={props.mobvid} type="video/mp4" className="mobile" />
        </video>
        <h1 className="text-uppercase text-white text-center  d-inline-block">
          {props.head}
          {props.subHead ? (
            <h2 className={props.textAlign}>{props.subHead}</h2>
          ) : (
            <></>
          )}
          {props.subHead2 ? (
            <h6 className={props.textAlign}>{props.subHead2}</h6>
          ) : (
            <></>
          )}
        </h1>
      </section>
    </div>
  );
};

export default Banner;
