import React, { useState } from "react";
import "./portfolio.scss";
import Bridges from "../bridges/bridges";
import Banner from "../shared/banner";
import Showcase from "../showcase/showcase";
import Portfoliocard from "../portfoliocard/portfoliocard";
import Explore from "../explore/explore";
import LetsTalk from "../letsTalk";
import Animatedheading2 from "../animatedheading2/animatedheading2";
import ScrollToTop from "../scroll/scroll";
import { useTranslation } from "react-i18next";

function Portfolio() {
  ScrollToTop();
  const [activeFilter, setActiveFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const {t} = useTranslation()
  const data = [
    // AI
    {
      imgsrc: "/assets/images/portfoliocards/hostguru.webp",
      title: t('portfolioItems.hostGuruAI'),
      category: "Artificial Intelligence",
      desc: "With Host Guru, streamline the process of getting the best hotel deals all in one place. Using Al algorithms and machine learning, Host Guru streamlines all the best offers and presents them to you to pick and make the most of hospitality for your guests.",
      link: "https://host-guru.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/autored.webp",
      title: t('portfolioItems.autoRed'),
      category: "Artificial Intelligence",
      desc: "Using AI at its core, Auto Red gives you a competitive edge in the competition in the realm of social media. From providing real time comparisons and analysis of your competitors, to providing stats from your social media to help you scale and grow better.",
      link: "https://auto-red.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/simpli.webp",
      title: t('portfolioItems.simpliRfp'),
      category: "Artificial Intelligence",
      desc: "Simpli RFP is an innovative solution that provides contract summaries, accelerates contract analysis and transforms the Request for Proposal (RFP) process. Utilizing advanced AI algorithms, Simpli RFP automates and optimizes the creation, management, and response to government contracts, providing a more efficient and effective workflow. ",
      link: "https://www.simplirfp.com/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/query.webp",
      title: t('portfolioItems.queryFlow'),
      category: "Artificial Intelligence",
      link: "https://queryflo.datatechcon.com/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/resume.webp",
      title: t('portfolioItems.resumeFi'),
      category: "Artificial Intelligence",
      desc: "The AI Resume Builder employs advanced algorithms to analyze user input, offering personalized suggestions and improvements for each section. This ensures that resumes are not only visually appealing but also optimized for applicant tracking systems (ATS), increasing the likelihood of securing interviews. Octaloop's commitment to technological innovation shines through in the AI Resume Builder project, providing users with a powerful and user-friendly tool to elevate their job-seeking journey. This client project exemplifies Octaloop Technologies' dedication to leveraging AI for practical and impactful solutions in the professional realm.",
      link: "https://www.resumifi.com/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/misterai.webp",
      title: t('portfolioItems.misterAI'),
      category: "Artificial Intelligence",
      link: "https://www.misterai.io/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/optimal.webp",
      title: t('portfolioItems.optimalPricingTool'),
      category: "Artificial Intelligence",
      desc: "Using AI algorithms and image processing, Optimal Pricing Tool saves time and effort while reducing human error by processing data from price sheets and presenting the most efficient and productive offer for any given product.",
      link: "https://optimal-pricing.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/groupland.webp",
      title: t('portfolioItems.groupLand'),
      category: "Artificial Intelligence",
      desc: "With Group Landev, you can rest easy about your house and everything construction related. From all sorts of exterior maintenance, repairs and renovations to new projects, Group Landev meets your expectations with excellent and innovative customer service.",
      link: "https://group-land.vercel.app/",
    },
    // {
    //   imgsrc: "/assets/images/portfoliocards/dispatch.webp",
    //   title: "dispatch ai",
    //   category: "Artificial Intelligence",
    //   desc: "Dispatch AI aims to transform fuel logistics. Optimizing supply chain efficiency seamlessly from warehouses to petrol stations, ushering in a revolutionary era for streamlined operations using AI and blockchain at its backbone to ensure transparency for all records.",
    // },
    {
      imgsrc: "/assets/images/portfoliocards/lawvocate.webp",
      title: t('portfolioItems.lawvocate'),
      category: "Artificial Intelligence",
      desc: "Lawvocate ai serves to save users from expensive legal consultation, long waits for appointments, and confusing legal texts. Along with the ability to automate your legal research and paperwork, granting you more free time for cases that matter.",
      link: "https://lawvocate-ai.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/dispatchlanding.webp",
      title: t('portfolioItems.playBook'),
      category: "Artificial Intelligence",
      link: "https://play-book.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/supervet.webp",
      title: t('portfolioItems.superVet'),
      category: "Artificial Intelligence",
      link: "https://supervet.io/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/threedot.webp",
      title: t('portfolioItems.threeDotLink'),
      category: "Artificial Intelligence",
      desc: "3dotlink leverages the power of AI to redefine the creation and experience of NFTs. Unlike conventional NFT projects, 3dotlink places a strong emphasis on authenticity and diversity, incorporating Know Your Customer (KYC) and artistic verification processes. The platform introduces limited edition NFTs, ensuring digital art verification and providing Web3 wallet access with flexibility.",
      link: "https://www.3dotlink.io/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/root.webp",
      title: t('portfolioItems.theRoot'),
      category: "Artificial Intelligence",
      desc: "The Root aims to address the lack of a consolidated view of corporate, global, and local media by integrating all data sources and retrieving the latest data from different sources to arrange, organize and sort the accurate data for users to engage with result driven activities.",
      link: "https://the-root.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/quizbuzz.webp",
      title: t('portfolioItems.quizBizz'),
      category: "Artificial Intelligence",
      link: "https://www.quizbuzz.ai/",
    },
    // {
    //   imgsrc: "/assets/images/portfoliocards/voxr.png",
    //   title: "voxr",
    //   category: "AI",
    // },
    // Gaming
  
    {
      imgsrc: "/assets/images/portfoliocards/supervetmob.webp",
      title: t('portfolioItems.supervetMobi'),
      category: "Gaming",
      desc: "Serves to bring you the Supervet experience on your mobile device. Integrating the power of AI and blockchain to deliver a DeFi experience.  Supervet is an animal rescue game that makes the most of the recent technology and deliver it to the players.",
      link: "https://play.google.com/store/apps/details?id=com.myall",
    },
    {
      imgsrc: "/assets/images/portfoliocards/defly.webp",
      title: t('portfolioItems.deflyBall'),
      category: "Gaming",
      desc: "Explore the virtual gaming world of Flyball on DeFly Ball, a platform built on the Binance Smart Chain. With stunning 3D art and characters, this gaming experience incorporates a Play-to-Earn (P2E) model, allowing users to sell, buy, and mint valuable NFTs in the NFT marketplace. Octaloop Technologies, as the driving force behind DeFly Ball, has contributed to the creation of a dynamic gaming environment that blends entertainment with blockchain technology.",
      link: "https://www.deflyball.com/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/trekverse.webp",
      title: t('portfolioItems.trekverse'),
      category: "Gaming",
      desc: "Aiming to revolutionize tourism with technology, Trekverse presents itself as a Metaverse project. As a Meta touring platform, Metaverse enables people to visit the world in the metaverse with AR and VR technology. Without becoming a strain on finances and time, Metaverse is the solution for all traveling enthusiasts.",
      link: "https://trekverse-one.vercel.app/",
    },
    // {
    //   imgsrc: "/assets/images/portfoliocards/quizbuzz.webp",
    //   title: "quiz buzz  ",
    //   category: "Gaming",
    // },
    // WEB
    {
      imgsrc: "/assets/images/portfoliocards/mediq.webp",
      title: t('portfolioItems.med1q'),
      category: "Web2",
    },
    {
      imgsrc: "/assets/images/portfoliocards/tekonecta.webp",
      title: t('portfolioItems.tekonectaProfileBuilder'),
      category: "Web2",
      link: "https://te-konecta-new.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/tekonecta2.webp",
      title: t('portfolioItems.tekonecta'),
      category: "Web2",
      desc: "Tekonecta allows you to create a personalized and customizable page that houses all the important links that you want to share with your audience. It's a link-in-bio solution that can be used on social platforms like Instagram, TikTok, Twitch, Facebook, YouTube, Twitter, LinkedIn, and more.",
      link: "https://te-konecta-new.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/toycove.webp",
      title: t('portfolioItems.toyCove'),
      category: "Web2",
      link: "https://toycove-two.vercel.app/",
    },
    // {
    //   imgsrc: "/assets/images/portfoliocards/hmgarden.webp",
    //   title: "h&m Garden",
    //   category: "Web2",
    // },
    {
      imgsrc: "/assets/images/portfoliocards/melda.webp",
      title: t('portfolioItems.meldaSol'),
      category: "Web2",
      link: "https://meldasolutions.octalooptechnologies.com/",
    },
    // Blockchain
    {
      imgsrc: "/assets/images/portfoliocards/metamuze.webp",
      title: t('portfolioItems.metamuze'),
      category: "Blockchain",
      desc: "Metamuze’s primary objective was to create a platform facilitating the sale, raffle, and auction of both physical and digital items to the community, utilizing native tokens such as SOL and USDC. Metamuze boasts an extensive collection of NFTs and physical items, providing users with a seamless experience for buying, selling, and minting. The platform offers a secure environment with smart contract functionality, ensuring a fair and transparent marketplace. This feature guarantees that there is no room for cheating on the platform, instilling trust among users. Additionally, Metamuze empowers creators by allowing them to set prices according to their preferences, providing a flexible and user-centric marketplace. Octaloop Technologies has been instrumental in bringing the vision of Metamuze to life, contributing to the creation of a dynamic and secure NFT marketplace on the Solana blockchain.",
      link: "https://metamuse.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/astrality.webp",
      title: t('portfolioItems.astrality'),
      category: "Blockchain",
      desc: "Astrality, an AI-driven metaverse project, offers digital land ownership with selling and renting capabilities. Its marketplace and socialfi features enhance immersive experiences, while brand partnerships enable innovative advertising solutions for marketing in the metaverse.",
      link: "https://astrality.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/lighthouse.webp",
      title: t('portfolioItems.lightHouse'),
      category: "Blockchain",
      link: "https://lighthouse-tau.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/blocknotes.webp",
      title: t('portfolioItems.blocknotes'),
      category: "Blockchain",
      link: "https://blocknotes-new.vercel.app/",
    },
    {
      imgsrc: "/assets/images/portfoliocards/dstreak.webp",
      title: t('portfolioItems.dStreak'),
      category: "Blockchain",
      desc: "D-Streak operates on the Polygon blockchain, leveraging high-performance infrastructure for decentralized trading, D-apps development, interoperability with other blockchains, and seamless support for smart contracts. The project aims to empower car drivers by offering a simple way to earn cryptocurrency through the D-Streak Android or iOS mobile app. As the creators of D-Streak, Octaloop Technologies demonstrates its commitment to pioneering innovative solutions at the intersection of technology and real-world challenges. D-Streak represents a forward-thinking approach to leveraging blockchain and decentralized technologies for practical, everyday benefits.",
      link: "https://www.dstreak.io/",
    },
    // {
    //   imgsrc: "/assets/images/portfoliocards/lil.webp",
    //   title: "lil frens",
    //   category: "Blockchain",
    //   desc: "As a client project of Octaloop, Lil Frens represents our dedication to fostering innovative NFT ecosystems. Through meticulous attention to detail and expertise in blockchain technologies, we have contributed to the creation of Lil Frens—a community-driven platform that goes beyond traditional NFT offerings, providing users with both utility and a sense of belonging in the ever-expanding NFT space.",
    // },
    // {
    //   imgsrc: "/assets/images/portfoliocards/richribbit.png",
    //   title: "rich ribbits",
    //   category: "Blockchain",
    // },
  ];


  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    setCurrentPage(1);
  };

  const filteredData =
    activeFilter === "All"
      ? data
      : data.filter((item) => item.category === activeFilter);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);

  const currentPageData = filteredData.slice(startIndex, endIndex);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  


  return (
    <section className="portfolio-sec">
      <Banner
        subHead2={t('portfolio.subHead2')}
        head={t('portfolio.head')}
        textAlign="text-center"
        desktopvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        mobvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        poster="/assets/images/aboutposter.webp"
      />
      <Showcase />
      <div className="portfoliocards-sec">
        <Animatedheading2
          innerfirst={t('portfolio.innerfirst')}
          innersecond={t('portfolio.innersecond')}
          innerthird={t('portfolio.innerthird')}
        />
        <div className="cards-tabs">
          <div className="tabs-inner">
            <p
              className={`large-para ${activeFilter === "All" ? "active" : ""}`}
              onClick={() => handleFilterClick("All")}
            >
             {t('portfolio.all')}
            </p>
            <p
              className={`large-para ${
                activeFilter === "Artificial Intelligence" ? "active" : ""
              }`}
              onClick={() => handleFilterClick("Artificial Intelligence")}
            >
              {t('portfolio.ai')}
            </p>
            <p
              className={`large-para ${
                activeFilter === "Blockchain" ? "active" : ""
              }`}
              onClick={() => handleFilterClick("Blockchain")}
            >
              {t('portfolio.blockchain')}{" "}
            </p>
            <p
              className={`large-para ${
                activeFilter === "Web2" ? "active" : ""
              }`}
              onClick={() => handleFilterClick("Web2")}
            >
             {t('portfolio.web2')}
            </p>
            <p
              className={`large-para ${
                activeFilter === "Gaming" ? "active" : ""
              }`}
              onClick={() => handleFilterClick("Gaming")}
            >
              {t('portfolio.gaming')}
            </p>
          </div>
        </div>
        <div className="portfolio-cards">
          {currentPageData.map((item) => (
            <Portfoliocard
              key={item.id}
              imgsrc={item.imgsrc}
              title={item.title}
              category={item.category}
              link={item.link}
            />
          ))}
        </div>
        {filteredData.length > 9 && (
          <div className="pagination">
            <div className="pag-inner">
              {Array.from({ length: totalPages }, (_, index) => (
                <div
                  key={index}
                  className={`pag-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                  onClick={() => handlePagination(index + 1)}
                >
                  {index + 1}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Bridges />
      <Explore />
      <LetsTalk />
    </section>
  );
}

export default Portfolio;

