import React, { useState } from "react";
import "./fever.scss";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
function Fever() {
  const [show, setShow] = useState("tab1");

  const handleTabChange = (tab) => {
    setShow(tab);
  };
  const {t} = useTranslation()

  return (
    <section className="blockfever-sec">
      <div className="inner">
        <div className="left">
          <div className="head">
            <img
              src="/assets/icons/fevertop.svg"
              alt="..."
              className="top-img"
            />
            <div className="main-head">
              <h3 className="normal-title">{t('fever.blockchain_fever')}</h3>

              <div>
                <h3 className="shadow-title">{t('fever.title')}</h3>
              </div>
            </div>
            <img
              src="/assets/icons/feverbottm.svg"
              alt="..."
              className="bottom-img"
            />
          </div>
          <p className="large-para">
          {t('fever.description')}
          </p>
          <div className="d-flex flex-column tabs-para">
            <div
              className={`${
                show.includes("tab1") ? "bottom-head active" : "bottom-head "
              }`}
              onClick={() => handleTabChange("tab1")}
            >
              <Icon icon="icomoon-free:infinite" width="18" height="18" />
              <h3>{t('fever.tab1')}</h3>
            </div>
            <div
              className={`${
                show.includes("tab2") ? "bottom-head active" : "bottom-head "
              }`}
              onClick={() => handleTabChange("tab2")}
            >
              <Icon icon="icomoon-free:infinite" width="18" height="18" />
              <h3>{t('fever.tab2')}</h3>
            </div>
            <div
              className={`${
                show.includes("tab3") ? "bottom-head active" : "bottom-head "
              }`}
              onClick={() => handleTabChange("tab3")}
            >
              <Icon icon="icomoon-free:infinite" width="18" height="18" />
              <h3>{t('fever.tab3')}</h3>
            </div>
            <div
              className={`${
                show.includes("tab4") ? "bottom-head active" : "bottom-head "
              }`}
              onClick={() => handleTabChange("tab4")}
            >
              <Icon icon="icomoon-free:infinite" width="18" height="18" />
              <h3>{t('fever.tab4')}</h3>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="img-outer">
            {show.includes("tab1") ? (
              <img src="/assets/images/blockfever.webp" alt="..." />
            ) : show.includes("tab2") ? (
              <img src="/assets/images/blockfever2.webp" />
            ) : show.includes("tab3") ? (
              <img src="/assets/images/blockfever3.webp" />
            ) : (
              <img src="/assets/images/blockfever4.webp" />
            )}

            <img
              src="/assets/images/feverback.webp"
              alt="..."
              className="fever-back"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Fever;
