import React from "react";
import idea from "../../assets/icons/idea.svg";
import idea2 from "../../assets/icons/idea2.svg";
import member from "../../assets/icons/member.svg";
import member2 from "../../assets/icons/member2.svg";
import code from "../../assets/icons/code.svg";
import code2 from "../../assets/icons/code2.svg";
import { useTranslation } from "react-i18next";
const WorkFlow = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="work-flow">
        <div className="work-flow-content">
          <div className="work-flow-card">
            <div className="header">
              <img src={idea} alt="idea" className="notshow-icon" />
              <img src={idea2} alt="idea" className="show-icon" />
            </div>
            <label className="text-light-purple mt-4">
              {t("workFlow.defineYourIdeas")}
            </label>
            <p className="medium mt-3 text-center text-gray ">
              {t("workFlow.defineYourIdeasDescription")}
            </p>
          </div>

          <div className="divider">
            <span className="circle-1"></span>
            <span className="circle-2"></span>
            <span className="circle-3"></span>
            <span className="circle-4"></span>

            <span className="circle-center"></span>

            <span className="circle-4"></span>
            <span className="circle-3"></span>
            <span className="circle-2"></span>
            <span className="circle-1"></span>
          </div>

          <div className="work-flow-card">
            <div className="header">
              <img src={member} alt="idea" className="notshow-icon" />
              <img src={member2} alt="idea" className="show-icon" />
            </div>
            <label className="text-light-purple mt-4">
              {t("workFlow.workOnDesigns")}
            </label>
            <p className="medium mt-3 text-center text-gray ">
              {t("workFlow.workOnDesignsDescription")}
            </p>
          </div>

          <div className="divider">
            <span className="circle-1"></span>
            <span className="circle-2"></span>
            <span className="circle-3"></span>
            <span className="circle-4"></span>

            <span className="circle-center"></span>

            <span className="circle-4"></span>
            <span className="circle-3"></span>
            <span className="circle-2"></span>
            <span className="circle-1"></span>
          </div>

          <div className="work-flow-card">
            <div className="header">
              <img src={code} alt="idea" className="notshow-icon" />
              <img src={code2} alt="idea" className="show-icon" />
            </div>
            <label className="text-light-purple mt-4 text-center">
              {t("workFlow.codingAndReview")}
            </label>
            <p className="medium mt-3 text-center text-gray ">
              {t("workFlow.codingAndReviewDescription")}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WorkFlow;
