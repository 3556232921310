import React, { useState } from "react";
import "./portfoliocard.scss";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function Portfoliocard({ imgsrc, title, category, desc, link }) {
  const [modalShow, setModalShow] = useState(false);
  const {t} = useTranslation()
  console.log("links", link);
  const openNewWindow = () => {
    window.open(link, "_blank");
  };

  return (
    <section className="portfoliocard">
      <div className="card-top">
        <h2>{title}</h2>
        <div className="card-inner">
          <img src={imgsrc} alt="..." className="project-img" />
          <div className="card-layer">
            <button onClick={openNewWindow}>{t('cardLayer.preview')}</button>
            <button onClick={() => setModalShow(false)}>{t('cardLayer.detail')}</button>
          </div>
        </div>
        <img
          src="/assets/images/portfoliocards/portfoliocardback.webp"
          alt="..."
          className="animated-img"
        />
      </div>
      <h4>{title}</h4>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setModalShow(false)}
        className="portfolio-modal"
      >
        <div className="modal-header">
          <img
            className="pointer"
            src="/assets/icons/backarrow.svg"
            alt="..."
            onClick={() => setModalShow(false)}
          />
        </div>
        <Modal.Body>
          <div className="modal-left">
            <h2>{title}</h2>
            <img src={imgsrc} alt="..." className="project-img" />
            <button className="live-btn">GO LIVE</button>
          </div>
          <div className="modal-right">
            <div className="head">
              <h5>Description</h5>
              <span>{title}</span>
            </div>
            <p className="large-para">{desc}</p>
            <div className="stack">
              <h5>Technology Stack</h5>
              <div className="tools">
                <div className="tools-icons">
                  <h6>UI/UX & Logo</h6>
                  <div>
                    <img src="/assets/icons/stack/figma.svg" alt="..." />
                    <img src="/assets/icons/stack/ai.svg" alt="" />
                    <img src="/assets/icons/stack/ps.svg" alt="" />
                    <img src="/assets/icons/stack/ae.svg" alt="" />
                  </div>
                </div>
                <div className="tools-icons">
                  <h6>Frontend</h6>
                  <div>
                    <img src="/assets/icons/stack/visualstudio.svg" alt="..." />
                    <img src="/assets/icons/stack/bootstrap.svg" alt="" />
                    <img src="/assets/icons/stack/iconify.svg" alt="" />
                    <img src="/assets/icons/stack/adobe.svg" alt="" />
                  </div>
                </div>
                <div className="tools-icons">
                  <h6>Backend</h6>
                  <div>
                    <img src="/assets/icons/stack/aftereffect.svg" alt="..." />
                    <img src="/assets/icons/stack/illustrator.svg" alt="" />
                    <img src="/assets/icons/stack/photoshop.svg" alt="" />
                    <img src="/assets/icons/stack/logos.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Portfoliocard;
