import React from "react";
import hands from "../../assets/join/hands.webp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Join = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <div>
      <div className="join  ">
        <h2>
          {t('contactSection.header')}<span style={{ color: "#6F00FF" }}>?</span>
        </h2>
        <p>{t('contactSection.description')}</p>
        <button className="d-flex" onClick={() => navigate("/contact-us")}>
          {t('contactSection.buttonText')}
          <img src={hands} alt="hand-img" />
          <img
            src="/assets/handshake.gif"
            alt="gif"
            className="handshake img-fluid"
          />
          <div className="layer"></div>
        </button>
      </div>
    </div>
  );
};

export default Join;
