import React from "react";
import "./bridges.scss";
import Animatedheading from "../animatedheading/animatedheading";
import { useTranslation } from "react-i18next";
function Bridges() {
  const {t} = useTranslation()
  return (
    <section className="bridges-sec">
      <Animatedheading
        outer={t('bridges.outerHeading')}
        innerfirst={t('bridges.innerHeading1')}
        innersecond={t('bridges.innerHeading2')}
      />
      <div className="brige-cards">
        {data.map((item) => (
          <div className="bridge-card">
            <img src={item.imgsrc} alt="..." className="img-fluid" />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Bridges;

const data = [
  {
    imgsrc: "/assets/images/bridges/1.svg",
  },
  {
    imgsrc: "/assets/images/bridges/2.svg",
  },
  {
    imgsrc: "/assets/images/bridges/3.svg",
  },
  {
    imgsrc: "/assets/images/bridges/4.svg",
  },
  {
    imgsrc: "/assets/images/bridges/5.svg",
  },
  {
    imgsrc: "/assets/images/bridges/6.svg",
  },
  {
    imgsrc: "/assets/images/bridges/7.svg",
  },
  {
    imgsrc: "/assets/images/bridges/8.svg",
  },
  {
    imgsrc: "/assets/images/bridges/9.svg",
  },
  {
    imgsrc: "/assets/images/bridges/10.svg",
  },
  {
    imgsrc: "/assets/images/bridges/11.svg",
  },
  {
    imgsrc: "/assets/images/bridges/12.svg",
  },
  {
    imgsrc: "/assets/images/bridges/13.svg",
  },
  {
    imgsrc: "/assets/images/bridges/14.svg",
  },
];
