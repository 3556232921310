import React, { useContext } from "react";
import "./letsconnect.scss";
import Animatedheading from "../animatedheading/animatedheading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/language";
function Letsconnect() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, updateLanguage } = useContext(LanguageContext);

  return (
    <>
      <section className="letsconnect-sec">
        <div className="inner">
          <div className="left">
            <img src="/assets/icons/people.svg" alt="..." />
            <h5 className={language == "ar" ? "text-end " : ""}>
              {t("letsConnect.sharingExpertise")} <br className="mobile-br" />
              {t("letsConnect.relationship")}
            </h5>
          </div>
          <div className="right">
            <h3 onClick={() => navigate("/contact-us")}>
              {t("letsConnect.letsConnect")}
            </h3>
          </div>
        </div>
      </section>
    </>
  );
}

export default Letsconnect;
