import React from "react";
import "./marque.scss";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
function Marque() {
  const {t} = useTranslation()
  return (
    <div className="marquee-sec">
      <Marquee pauseOnHover>
        <div className="marquee-inner">
          <h6>{t('marquee.ai')}</h6>
          <h6>{t('marquee.web3')}</h6>
          <h6>{t('marquee.uiux')}</h6>
          <h6>{t('marquee.appDev')}</h6>
          <h6>{t('marquee.2d3d')}</h6>
          <h6>{t('marquee.gameDev')}</h6>
          <h6>{t('marquee.salesMarketing')}</h6>
          <h6>{t('marquee.qa')}</h6>
        </div>
      </Marquee>
    </div>
  );
}

export default Marque;
