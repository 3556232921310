import React from "react";
import dialler from "../../../assets/services/dialler.webp";
import voice from "../../../assets/services/voice.webp";
import bot from "../../../assets/services/bot.webp";
import generation from "../../../assets/services/generation.webp";
import agent from "../../../assets/services/agent.webp";
import resume from "../../../assets/services/resume.webp";
import visualization from "../../../assets/services/visualization.webp";
import extraction from "../../../assets/services/extraction.webp";
import automation from "../../../assets/services/automation.webp";
import predectior from "../../../assets/services/predectior.webp";
import Animatedheading from "../../animatedheading/animatedheading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Services = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const serviceCard = [
    {
      icons: dialler,
      heading: t('services.ai_dialler'),
    },
    {
      icons: voice,
      heading: t('services.ai_voice_assistant'),
    },
    {
      icons: bot,
      heading: t('services.ai_chat_assistant'),
    },
    {
      icons: generation,
      heading: t('services.ai_image_generation'),
    },
    {
      icons: agent,
      heading: t('services.custom_ai_agents'),
    },
    {
      icons: resume,
      heading: t('services.ai_resume_builder'),
    },
    {
      icons: visualization,
      heading: t('services.ai_visualization'),
    },
    {
      icons: extraction,
      heading: t('services.ai_data_extraction_engine'),
    },
    {
      icons: automation,
      heading: t('services.ai_automation'),
    },
    {
      icons: predectior,
      heading: t('services.ai_predictor'),
    },
  ];
  return (
    <div className="services-sec">
      <Animatedheading
       outer={t('services.services_innovations')}
       innerfirst={t('services.services_we_provide')}
       innersecond={t('services.provide_in_ai')}
      />
      <div className="services">
        <div className="services-content">
          {serviceCard.map((item, index) => (
            <div
              className="service-card" key={index}
              onClick={() => navigate("/contact-us")}
              data-content={t('quote')}
            >
              <img src={item.icons} alt="" />
              <h6>{item.heading}</h6>
              {/* <div className="quote">get a quote</div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
