import React from "react";
import "./teammembercard.scss";
import { Icon } from "@iconify/react";
function Teammembercard(props) {
  return (
    <sec className="teammembercard">
      <div className="top-div">
        <img src={props.imgsrc} alt="..." className="member-img" />
        <div className="img-back"></div>
        <div className="social-links">
          <a href={props.linkedin} target="_blank">
            <img src="/assets/icons/membercard/linkedin.svg" alt="" />
          </a>
        </div>
      </div>

      <div className="detail">
        <h3>{props.name}</h3>
        <p className="small-para">{props.role}</p>
        <div className="octa-div">
          <Icon icon="icomoon-free:infinite" width="36" height="25" />
        </div>
      </div>
    </sec>
  );
}

export default Teammembercard;
