import React from "react";
import "./swiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Autoplay,
  EffectCoverflow,
  EffectCreative,
  Navigation,
  Pagination,
  Parallax,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/parallax";
import "./aiprojects.scss";
import { useTranslation } from "react-i18next";


const FeatureWork = () => {
  const {t} = useTranslation()
  const data = [
  {
    imgsrc: "/assets/images/aiprojects/aiproject1.webp",
    number: t('aiprojects.numbers.1'),
    head: t('aiprojects.mister_ai'),
    para: t('aiprojects.mister_ai_description')
  },
  {
    imgsrc: "/assets/images/aiprojects/aiproject2.webp",
    number: t('aiprojects.numbers.2'),
    head: t('aiprojects.simpli_rfp'),
    para: t('aiprojects.simpli_rfp_description')
  },
  {
    imgsrc: "/assets/images/aiprojects/aiproject3.webp",
    number: t('aiprojects.numbers.3'),
    head:  t('aiprojects.resume_fi'),
    para: t('aiprojects.resume_fi_description')
  },
  {
    imgsrc: "/assets/images/aiprojects/aiproject4.webp",
    number: t('aiprojects.numbers.4'),
    head: t('aiprojects.host_guru'),
    para: t('aiprojects.host_guru_description'),
  },
  {
    imgsrc: "/assets/images/aiprojects/aiproject5.webp",
    number: t('aiprojects.numbers.5'),
    head: t('aiprojects.vox_r'),
    para: t('aiprojects.vox_r_description')
  },
  {
    imgsrc: "/assets/images/aiprojects/aiproject6.webp",
    number: t('aiprojects.numbers.6'),
    head: t('aiprojects.lawvocate'),
    para: t('aiprojects.lawvocate_description'),
  },
  {
    imgsrc: "/assets/images/aiprojects/aiproject7.webp",
    number: t('aiprojects.numbers.7'),
    head: t('aiprojects.auto_red'),
    para: t('aiprojects.auto_red_description')
  },
  {
    imgsrc: "/assets/images/aiprojects/aiproject8.webp",
    number: t('aiprojects.numbers.8'),
    head: t('aiprojects.three_dot_link'),
    para: t('aiprojects.three_dot_link_description')
  },
];

  const swiperParameters = {
    modules: [
      A11y,
      // Autoplay,
      EffectCoverflow,
      EffectCreative,
      Navigation,
      Parallax,
    ],
    navigation: {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    },
    watchSlidesProgress: true,
    autoplay: { pauseOnMouseEnter: true, enabled: true, delay: 2000 },
    effect: "coverflow",
    slidesPerGroupAuto: false,
    navigation: true,
    loop: true,
    creativeEffect: {
      next: { shadow: true },
      prev: { shadow: true },
      limitProgress: 5,
    },
    parallax: { enabled: true },
    speed: 2100,
    coverflowEffect: { depth: 112 },
  };

  return (
    <>
      <div
        className="featuredWork "
        style={{
          height: "100vh",

          overflowX: "hidden",
        }}
      >
        <Swiper {...swiperParameters}>
          {data.map((item, index) => (
            <SwiperSlide className="swiper-slide-3f38" key={index}>
              <div className="slider2 d-flex slider-3">
                <div className="left">
                  <div className="later">
                    <div className="head-outer">
                      <div className="head-inner">{t('aiprojects.ai_projects')}</div>
                    </div>
                    <h1 className="text-white">
                      <span className="text-greenish-blue number">
                        <span className="filled" style={{ color: "#6F00FF" }}>
                          {item.number}
                        </span>
                        <span className="stroke">{item.number}</span>
                      </span>
                      <span className="hash">#</span> <br />
                      {item.head}
                      <div className="layer rounded-circle"></div>
                    </h1>
                  </div>
                  <p className="text-white large-para mt-lg-2">{item.para}</p>
                </div>
                <div className="right">
                  <img src={item.imgsrc} alt="img" className="img-fluid" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default FeatureWork;

