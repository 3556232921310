import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAkrFWl-iqGSbXrfDViLDsmaSL8QGppeGQ",
  authDomain: "octaloop-aa809.firebaseapp.com",
  projectId: "octaloop-aa809",
  storageBucket: "octaloop-aa809.appspot.com",
  messagingSenderId: "340060507740",
  appId: "1:340060507740:web:8476e4661e6b4ab5956099",
  measurementId: "G-1WZW7CLW5R",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestoredb = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { firestoredb, auth, storage, analytics };
