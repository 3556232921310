import React from "react";
import "./showcase.scss";
import { useTranslation } from "react-i18next";
function Showcase() {
  const {t} = useTranslation()
  return (
    <section className="showcase-sec">
      <video
        playsInline
        autoPlay
        muted
        loop
        poster="/assets/images/portfolioposter.webp"
      >
        <source
          src=" https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/portfolio.mp4"
          type="video/mp4"
        />
      </video>
      <div className="left">
        <h1>{t('showcase.title')}</h1>
        <div className="para-div">
          <p className="large-para">
           {t('showcase.introParagraph1')}
          </p>
          <p className="large-para">
          {t('showcase.introParagraph2')}
          </p>
          <p className="large-para">
          {t('showcase.introParagraph3')}
          </p>
        </div>
      </div>
    </section>
  );
}

export default Showcase;
