import React from "react";
import "./maestro.scss";
import WorkFlow from "../home/workFlow";
import About from "../home/about";
import poster from "../../assets/img/workflow-video-poster.webp";

function Maestro() {
  return (
    <section className="maestro-sec">
      <video playsInline autoPlay muted loop poster={poster}>
        <source
          src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/workflow.mp4"
          type="video/mp4"
        />
      </video>
      <div className="bg-layer"></div>

      <WorkFlow />
      <About />
    </section>
  );
}

export default Maestro;
